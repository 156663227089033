/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import momentPropTypes from 'react-moment-proptypes';
import scrollToComponent from 'react-scroll-to-component';
import classnames from 'classnames';
import { Button, ToggleButton, ToggleButtonGroup } from '@glooko/common-ui';
import images from '~/utils/ImageStore';
import detectOs from '~/utils/detectOs';
import PasswordField from '../../shared/PasswordField/PasswordField';
import DobPicker from '../../shared/DobPicker/DobPicker';
import Consents from '../AccountInformation/Consents/Consents';
import EnrollmentWizardNavigation from '../EnrollmentWizardNavigation';
import GuardianInfo from '../AccountInformation/GuardianInfo/GuardianInfo';
import withTranslate from '../../shared/withTranslate/withTranslate';
import {
  emailActivationfieldValidators,
  consentsValid,
  sizeValid,
  strengthValue,
} from '../AccountInformation/validations/accountInformation';
import MobileEmailActivationContainer from '../EmailActivation/Mobile/MobileEmailActivationContainer';
import TextSent from '../EmailActivation/TextSent/TextSent';
import './style.sass';

const formFields = [
  'password',
  'consents',
  'dateOfBirth',
  'gaurdianInfo',
];

class EmailActivation extends React.Component {
  constructor() {
    super();
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onEmailActivate = this.onEmailActivate.bind(this);
    this.state = {
      isUserOnMobileClient: this.checkMobilePlatform(),
      selectedSegment: 'webSegment',
      textMessageSent: false,
    };
  }

  componentDidMount() {
    const { isUserOnMobileClient } = this.state;
    const { tempToken, professional } = queryString.parse(this.props.location.search);
    this.onChangeValue(tempToken, 'tempToken');
    this.onChangeValue(professional === 'true', 'professional');
    if (isUserOnMobileClient) {
      this.props.onMobileStartedActivation();
    } else {
      this.props.onWebStartedActivation();
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.onEmailActivate(e);
  }

  onEmailActivate(e) {
    if (this.props.isValid || (e.shiftKey && (e.altKey || e.ctrlKey))) {
      this.props.onEmailActivate();
    } else {
      this.touchFields();
      formFields.some((field) => {
        const ref = this[field.concat('Ref')];
        return !emailActivationfieldValidators[field](this.props[field], this.props, ref) &&
          scrollToComponent(ref, { align: 'top', offset: -8, duration: 500 });
      });
    }
  }

  onChangeValue(value, attribute) {
    this.props.onUpdateAttribute({ value, attribute });
  }

  setTextMessageSentStatus() {
    this.setState(prevState => ({
      textMessageSent: !prevState.textMessageSent,
    }));
  }

  touchFields() {
    formFields.forEach((field) => {
      const ref = this[field.concat('Ref')];
      if (ref && ref.touch) { ref.touch(); }
    });
  }

  checkMobilePlatform = () => detectOs.isIos() || detectOs.isAndroid() || detectOs.isBlackBerry();

  handleOnClickActivateOnApp = () => {
    const { onMobileClickedActivateOnApp } = this.props;
    // eslint-disable-next-line camelcase
    const { branch_link } = queryString.parse(this.props.location.search);
    onMobileClickedActivateOnApp();
    window.location.assign(branch_link);
  }

  handleOnClickActivateOnWeb = () => {
    this.setState(prevState => ({ isUserOnMobileClient: !prevState.isUserOnMobileClient }));
  }

  handleOnSelectedSegmentChange = (e, segmentId) => {
    const { onWebClickedTab } = this.props;
    this.setState({ selectedSegment: segmentId });
    const tab = segmentId === 'webSegment' ? 'Web' : 'Mobile';
    onWebClickedTab(tab);
  }

  renderWebAndMobileSegmentedButtons = () => {
    const { t } = this.props;
    return (
      <div className="selectWebOrMobileActivationContainer">
        <hr />
        <ToggleButtonGroup
          exclusive
          value={this.state.selectedSegment}
          onChange={this.handleOnSelectedSegmentChange}
          className="toggleButtonGroupRounded"
          dataAttributes={{ testid: 'email-activation' }}
        >
          <ToggleButton value="webSegment" dataAttributes={{ testid: 'email-activation-web-segment' }}>
            {t('webSegment')}
          </ToggleButton>
          <ToggleButton value="mobileSegment" dataAttributes={{ testid: 'email-activation-mobile-segment' }}>
            {t('mobileSegment')}
          </ToggleButton>
        </ToggleButtonGroup>
        <hr />
      </div>
    );
  }

  renderMobileBrowserActivationScreen = () => {
    const { t } = this.props;
    return (
      <div className="Mobile-View-Activate">
        <Button
          onClick={() => this.handleOnClickActivateOnApp()}
          className="Mobile-View-Activate-App-Button"
          dataAttributes={{ testid: 'email-activation-activate-on-app' }}
        >
          {t('activateOnTheApp')}
        </Button>
        <Button
          onClick={() => this.handleOnClickActivateOnWeb()}
          className="Mobile-View-Activate-Web-Button"
          variation="link"
          dataAttributes={{ testid: 'email-activation-activate-on-web' }}
        >
          {t('activateOnTheWeb')}
        </Button>
      </div>
    );
  }

  renderComputerBrowserActivationScreen = () => {
    const { t, registerError } = this.props;
    const mobileSegmentClasses = classnames({
      segmentedSection: true,
      selected: this.state.selectedSegment === 'mobileSegment',
    });
    const webSegmentClasses = classnames({
      segmentedSection: true,
      selected: this.state.selectedSegment === 'webSegment',
    });
    return (
      <React.Fragment>
        {this.renderWebAndMobileSegmentedButtons()}
        <div className="EmailActivation-rounded" />
        <div className={mobileSegmentClasses} >
          <MobileEmailActivationContainer
            setTextMessageSentStatus={() => this.setTextMessageSentStatus()}
          />
        </div>
        <div className={webSegmentClasses} >
          <div className="EmailActivation-web">
            <form className="EmailActivation-form" onSubmit={this.onSubmit}>
              <PasswordField
                onChange={(v) => { this.onChangeValue(v, 'password'); }}
                value={this.props.password}
                ref={(el) => { this.passwordRef = el; }}
                onKeyPress={() => {}}
                sizeValid={sizeValid}
                strengthValue={strengthValue}
                withStrengthMeter
                required
              />
              {!this.props.professional && (
                <DobPicker
                  label={t('birthDate')}
                  language={this.props.language}
                  onDateChange={(v) => { this.onChangeValue(v, 'dateOfBirth'); }}
                  value={this.props.dateOfBirth}
                  ref={(el) => {
                    this.dateOfBirthRef = el;
                  }}
                />
              )}
              <GuardianInfo
                label={this.props.t('birthDate')}
                dateOfBirth={this.props.dateOfBirth}
                majorityAge={this.props.majorityAge}
                onChange={(v) => { this.onChangeValue(v, 'guardianInfo'); }}
                values={this.props.guardianInfo}
                ref={(el) => { this.gaurdianInfoRef = el; }}
                isNewEmailNotification
                immediateGuardianConsent={false}
              />
              {!this.props.professional &&
                <Consents
                  label={t('termsAndConditions')}
                  onChange={(v) => { this.onChangeValue(v, 'consents'); this.forceUpdate(); }}
                  isValid={consentsValid}
                  values={this.props.consents}
                  ref={(el) => { this.consentsRef = el; }}
                  isNewEmailNotification
                  dateOfBirth={this.props.dateOfBirth}
                  majorityAge={this.props.majorityAge}
                  immediateConsent={false}
                />
              }
              {this.props.registerStatus === 'failed' && (
                <div className="AccountInformation-form-error">
                  {(registerError && registerError.date_of_birth &&
                    registerError.date_of_birth[0] === 'invalid_date_of_birth_research') ?
                    t('emailActivateErrorResearch') : t('emailActivateError')}
                </div>
              )}
              <EnrollmentWizardNavigation
                canContinue={this.props.isValid}
                onContinue={this.onEmailActivate}
                primaryLabel={t('activate')}
                showContinue
              />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { t } = this.props;
    if (this.state.textMessageSent) {
      return (
        <TextSent setTextMessageSentStatus={() => this.setTextMessageSentStatus()} />
      );
    }
    return (
      <div className="EmailActivation">
        <div className="EmailActivation-header">
          <div className="EmailActivation-header-col-1">
            <img
              className="EmailActivation-headerImage"
              alt=""
              src={images.activation}
            />
          </div>
          <div className="EmailActivation-header-col-2">
            <div className="EmailActivation-header-title">
              {this.state.isUserOnMobileClient
                ? t('activateOnTheGlookoApp')
                : t('activateYourAccount')}
            </div>
            {!this.props.professional && (
              <div className="EmailActivation-header-sub">
                {t('activateDetail')}
              </div>
            )}
          </div>
        </div>
        {this.state.isUserOnMobileClient
          ? this.renderMobileBrowserActivationScreen()
          : this.renderComputerBrowserActivationScreen()}
      </div>
    );
  }
}

EmailActivation.propTypes = {
  consents: PropTypes.object,
  dateOfBirth: momentPropTypes.momentObj,
  majorityAge: PropTypes.number,
  language: PropTypes.string,
  location: PropTypes.object,
  onEmailActivate: PropTypes.func.isRequired,
  onUpdateAttribute: PropTypes.func.isRequired,
  password: PropTypes.string,
  isValid: PropTypes.bool,
  registerStatus: PropTypes.string,
  registerError: PropTypes.object,
  professional: PropTypes.bool,
  guardianInfo: PropTypes.object,
  t: PropTypes.func.isRequired,
  onWebStartedActivation: PropTypes.func,
  onWebClickedTab: PropTypes.func,
  onMobileStartedActivation: PropTypes.func,
  onMobileClickedActivateOnApp: PropTypes.func,
};

EmailActivation.defaultProps = {
  consents: undefined,
  location: undefined,
  registerStatus: undefined,
  registerError: undefined,
  dateOfBirth: undefined,
  majorityAge: undefined,
  isValid: false,
  language: 'en',
  password: undefined,
  professional: false,
  guardianInfo: undefined,
  onWebStartedActivation: () => {},
  onWebClickedTab: () => {},
  onMobileStartedActivation: () => {},
  onMobileClickedActivateOnApp: () => {},
};

export default withTranslate(EmailActivation);
