/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import scrollToComponent from 'react-scroll-to-component';
import { has } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader, LinkedText } from '@glooko/common-ui';
import { isOfAge } from '~/utils/dateUtils';
import TextField from '../../shared/TextField/TextField';
import PasswordField from '../../shared/PasswordField/PasswordField';
import Select from '../../shared/Select/Select';
import PhoneNumberField from '../../shared/PhoneNumberField/PhoneNumberField';
import DobPicker from '../../shared/DobPicker/DobPicker';
import CodeField from '../../shared/CodeField/CodeField';
import { getPartner, getCountry } from '../../utils/navigation';
import Consents from './Consents/Consents';
import GuardianInfo from './GuardianInfo/GuardianInfo';
import UnitOfMeasure from './UnitOfMeasure/UnitOfMeasure';
import ChoiceCard from './RegChoices/ChoiceCard';
import EnrollmentWizardNavigation from '../EnrollmentWizardNavigation';
import withTranslate from '../../shared/withTranslate/withTranslate';

import {
  emailValid,
  sizeValid,
  strengthValue,
  fieldValidators,
  consentsValid,
} from './validations/accountInformation';

import './style.sass';

const formFields = [
  'email',
  'password',
  'firstName',
  'lastName',
  'countryOfResidence',
  'mobilePhone',
  'dateOfBirth',
  'gaurdianInfo',
  'consents',
];

export class AccountInformation extends React.Component {
  constructor() {
    super();
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.tryAgainDob = this.tryAgainDob.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.state = { codeModalOpen: false };
  }

  componentDidMount() {
    this.props.fetchCountries(this.props.language);
    if (this.emailRef) this.emailRef.focus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.supportedCountries.length < this.props.supportedCountries.length) {
      this.setCountry();
    }
  }

  onRegister() {
    if (this.props.registerStatus === 'pending') {
      return;
    }

    if (this.props.isValid) {
      this.props.onRegister();
    } else {
      this.touchFields();
      formFields.some((field) => {
        const ref = this[field.concat('Ref')];
        return !fieldValidators[field](this.props[field], this.props, ref) &&
          scrollToComponent(ref, { align: 'top', offset: -8, duration: 500 });
      });
    }
  }

  onChangeValue(value, attribute) {
    this.props.onUpdateAttribute({ value, attribute });

    if (['dateOfBirth', 'countryOfResidence'].includes(attribute)) {
      // we want to potentially show the modal again after changing these values
      this.setState({ invalidDobModalShown: false });
    }
  }

  setCountry = () => {
    const countryCode = getCountry() || null;
    const partner = getPartner() || null;
    if (countryCode === null && partner !== 'op5') {
      return;
    }

    const countryCodeWithDefault = countryCode || 'usa';
    if (countryCodeWithDefault && this.isSupportedCountry(countryCodeWithDefault)) {
      this.props.onUpdateCountry(countryCodeWithDefault);
    }
  }

  isSupportedCountry = countryCode =>
    !!this.props.supportedCountries.find(country => (country.value === countryCode));

  removeAccountInfoErrorOnTyping = () => {
    const errorClass = document.getElementsByClassName('AccountInformation-form-error')[0];
    if (errorClass) {
      errorClass.classList.add('noShow');
    }
  };

  errorMessage() {
    const { registerError, t } = this.props;

    if (has(registerError, 'email')) {
      return t('registerErrorEmail');
    } else if (has(registerError, 'code')) {
      return t('registerErrorCode');
    }

    return t('registerErrorUnknown');
  }

  closeModal() { this.setState({ codeModalOpen: false }); }
  openModal() { this.setState({ codeModalOpen: true }); }

  tryAgainDob() {
    this.setState({ invalidDobModalShown: true });
  }

  handleGoBack() {
    this.handleChooseAdultOrMinor(undefined);
  }

  touchFields() {
    formFields.forEach((field) => {
      const ref = this[field.concat('Ref')];
      if (ref.touch) { ref.touch(); }
    });
  }

  handleChooseAdultOrMinor(choice) {
    this.setState({ invalidDobModalShown: false });
    this.props.onUpdateAttribute({ value: choice, attribute: 'registerAs' });
  }

  renderAdultOrMinorChoice() {
    const { loginUrl, t } = this.props;
    return (
      <React.Fragment>
        <div className="AccountInformation-header-small">
          {t('whoCreatingFor')}
        </div>
        <div className="AccountInformation-reg-choices">
          <ChoiceCard
            title={t('registerMyself')}
            details={t('registerMyselfDetails')}
            onClick={() => this.handleChooseAdultOrMinor('adult')}
            dataAttributes={{ testid: 'account-information-reg-myself' }}
          />
          <ChoiceCard
            title={t('registerMinor')}
            details={t('registerMinorDetails')}
            onClick={() => this.handleChooseAdultOrMinor('minor')}
            dataAttributes={{ testid: 'account-information-reg-minor' }}
          />
        </div>
        <div className="AccountInformation-login" >
          <LinkedText
            template={this.props.t('alreadyHaveAccount')}
            linkContent={[{ title: t('logIn'), url: loginUrl }]}
            dataAttributes={{ testid: 'account-information-login' }}
          />
        </div>
      </React.Fragment>
    );
  }

  renderMismatchedAgeModal(registerType) {
    const {
      dateOfBirth, majorityAge, t, registerAs,
    } = this.props;
    const isRegisteringMinor = registerAs === 'minor';
    const switchTo = isRegisteringMinor ? 'adult' : 'minor';
    const modalInfo = {
      adult: {
        title: t('notAdultTitle'),
        details: t('notAdultDetail'),
        switchButtonTitle: t('doMinor'),
        invalidDob: () => !isRegisteringMinor &&
          majorityAge && dateOfBirth && !isOfAge(dateOfBirth, majorityAge),
      },
      minor: {
        title: t('notMinorTitle'),
        details: t('notMinorDetail'),
        switchButtonTitle: t('doAdult'),
        invalidDob: () => isRegisteringMinor &&
          majorityAge && dateOfBirth && isOfAge(dateOfBirth, majorityAge),
      },
    };

    return (
      <Dialog
        size="small"
        open={!this.state.invalidDobModalShown && modalInfo[registerType].invalidDob()}
        onClose={this.tryAgainDob}
        dataAttributes={{ testid: `not-${registerType}-modal` }}
        className="AccountInformation-dob-modal"
      >
        <DialogHeader>
          <div className="AccountInformation-dob-modal-title">{modalInfo[registerType].title}</div>
        </DialogHeader>
        <DialogContent>
          <p className="AccountInformation-dob-modal-detail">
            { modalInfo[registerType].details }
          </p>
        </DialogContent>
        <DialogActions className="AccountInformation-modal-actions">
          <Button
            className="AccountInformation-modal-button"
            variation="secondary"
            onClick={() => this.handleChooseAdultOrMinor(switchTo)}
            dataAttributes={{ testid: `not-${registerType}-modal-${switchTo}` }}
          >{modalInfo[registerType].switchButtonTitle}
          </Button>
          <Button
            className="AccountInformation-modal-button"
            onClick={this.tryAgainDob}
            dataAttributes={{ testid: `not-${registerType}-modal-retry` }}
          >
            {t('tryAgain')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderAccountForm() {
    const {
      unitOfMeasure, t, registerAs,
    } = this.props;
    const isRegisteringMinor = registerAs === 'minor';
    const partner = getPartner();
    const accountInformationTitle = isRegisteringMinor ? t('minorDetails') : t('tellUsAboutYourself');

    return (
      <React.Fragment>
        <div className="AccountInformation-header-small">
          {accountInformationTitle}
          <div className="AccountInformation-sub-small">
            {t('accountInformationNote')}
          </div>
        </div>
        <form className="AccountInformation-form">
          <TextField
            isValid={emailValid}
            label={this.props.t('email')}
            onChange={(v) => { this.onChangeValue(v, 'email'); }}
            onKeyPress={this.removeAccountInfoErrorOnTyping}
            value={this.props.email}
            ref={(el) => { this.emailRef = el; }}
            required
          />
          <PasswordField
            sizeValid={sizeValid}
            strengthValue={strengthValue}
            onChange={(v) => { this.onChangeValue(v, 'password'); }}
            onKeyPress={this.removeAccountInfoErrorOnTyping}
            value={this.props.password}
            ref={(el) => { this.passwordRef = el; }}
            withStrengthMeter
            required
          />
          <TextField
            label={this.props.t('firstName')}
            onChange={(v) => { this.onChangeValue(v, 'firstName'); }}
            value={this.props.firstName}
            ref={(el) => { this.firstNameRef = el; }}
            required
          />
          <TextField
            label={this.props.t('lastName')}
            onChange={(v) => { this.onChangeValue(v, 'lastName'); }}
            value={this.props.lastName}
            ref={(el) => { this.lastNameRef = el; }}
            required
          />
          <Select
            label={this.props.t('countryOfResidence')}
            onChange={this.props.onUpdateCountry}
            options={this.props.supportedCountries}
            ref={(el) => { this.countryOfResidenceRef = el; }}
            value={this.props.countryOfResidence}
          />
          <PhoneNumberField
            onChangeCountryCode={(v) => { this.onChangeValue(v, 'countryCode'); }}
            onChangePhone={(v) => { this.onChangeValue(v, 'mobilePhone'); }}
            onChangePhoneCountry={
              (v) => { this.onChangeValue(v, 'phoneCountry'); }
            }
            onChangeConsent={
              (v) => { this.onChangeValue(v, 'receiveSms'); }
            }
            phoneCountry={this.props.phoneCountry}
            phoneNumber={this.props.mobilePhone}
            receiveSms={this.props.receiveSms}
            countryCode={this.props.countryCode}
            ref={(el) => { this.mobilePhoneRef = el; }}
            isOptional
          />
          <DobPicker
            label={this.props.t('birthDate')}
            language={this.props.language}
            initialDate={this.props.dateOfBirth}
            onDateChange={(v) => { this.onChangeValue(v, 'dateOfBirth'); }}
            value={this.props.dateOfBirth}
            ref={(el) => { this.dateOfBirthRef = el; }}
          />
          {isRegisteringMinor &&
            <GuardianInfo
              label={this.props.t('birthDate')}
              validateDob={false}
              dateOfBirth={this.props.dateOfBirth}
              majorityAge={this.props.majorityAge}
              immediateGuardianConsent={false}
              onChange={(v) => { this.onChangeValue(v, 'guardianInfo'); }}
              values={this.props.guardianInfo}
              ref={(el) => { this.gaurdianInfoRef = el; }}
            />
          }
          {partner !== 'op5' && (
          <CodeField
            onChange={(v) => {
              this.onChangeValue(v, 'code');
            }}
            onMoreInfo={this.openModal}
            value={this.props.code}
            ref={(el) => {
              this.codeRef = el;
            }}
          />)}
          <UnitOfMeasure
            defaultUOM={unitOfMeasure}
            visible={this.props.showUnitOfMeasure}
            onChange={(v) => { this.onChangeValue(v, 'meterUnits'); }}
          />
          {!isRegisteringMinor &&
            <Consents
              label={this.props.t('termsAndConditions')}
              dateOfBirth={this.props.dateOfBirth}
              majorityAge={this.props.majorityAge}
              immediateConsent={false}
              onChange={(v) => {
                this.onChangeValue(v, 'consents');
                this.forceUpdate();
              }}
              isValid={consentsValid}
              values={this.props.consents}
              ref={(el) => { this.consentsRef = el; }}
            />
          }
          {this.props.registerStatus === 'failed' &&
            <div className="AccountInformation-form-error">
              {this.errorMessage()}
            </div>
          }
          <EnrollmentWizardNavigation
            canContinue={this.props.isValid && this.props.registerStatus !== 'pending'}
            onContinue={this.onRegister}
            showBack
            secondaryLabel={t('back')}
            canGoBack
            onGoBack={this.handleGoBack}
            primaryLabel={this.props.registerStatus !== 'pending' ? t('register') : t('saving')}
            showContinue
          />
          <Dialog
            size="small"
            open={this.state.codeModalOpen}
            onClose={this.closeModal}
            dataAttributes={{ testid: 'account-information' }}
          >
            <DialogHeader dataAttributes={{ testid: 'account-information' }}><h2>{t('doYouHaveACode')}</h2></DialogHeader>
            <DialogContent dataAttributes={{ testid: 'account-information' }}>
              <p>
                { t('codeInfoModalPartOne') }
              </p>
              <p>
                { t('codeInfoModalPartTwo') }
              </p>
            </DialogContent>
            <DialogActions dataAttributes={{ testid: 'account-information' }}>
              <Button
                onClick={this.closeModal}
                dataAttributes={{ testid: 'account-information-ok' }}
              >
                {t('ok')}
              </Button>
            </DialogActions>
          </Dialog>
          {this.renderMismatchedAgeModal('adult')}
          {this.renderMismatchedAgeModal('minor')}
        </form>
      </React.Fragment>
    );
  }

  render() {
    const mainContent = !this.props.registerAs ?
      this.renderAdultOrMinorChoice() : this.renderAccountForm();

    return (
      <div className="AccountInformation">
        <div className="AccountInformation-header">
          {this.props.t('signUp')}
          <div className="AccountInformation-header-sub">
            {this.props.t('signUpDetail')}
          </div>
        </div>
        {mainContent}
      </div>
    );
  }
}

AccountInformation.propTypes = {
  code: PropTypes.string,
  consents: PropTypes.object,
  countryCode: PropTypes.string,
  countryOfResidence: PropTypes.string,
  dateOfBirth: momentPropTypes.momentObj,
  majorityAge: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string,
  guardianInfo: PropTypes.object,
  language: PropTypes.string,
  lastName: PropTypes.string,
  fetchCountries: PropTypes.func,
  onRegister: PropTypes.func.isRequired,
  onUpdateAttribute: PropTypes.func.isRequired,
  onUpdateCountry: PropTypes.func.isRequired,
  password: PropTypes.string,
  phoneCountry: PropTypes.string,
  mobilePhone: PropTypes.string,
  receiveSms: PropTypes.bool,
  unitOfMeasure: PropTypes.string,
  showUnitOfMeasure: PropTypes.bool,
  supportedCountries: PropTypes.arrayOf(PropTypes.object),
  isValid: PropTypes.bool,
  registerStatus: PropTypes.string,
  registerError: PropTypes.object,
  registerAs: PropTypes.string,
  t: PropTypes.func.isRequired,
  loginUrl: PropTypes.string.isRequired,
};

AccountInformation.defaultProps = {
  countryCode: undefined,
  countryOfResidence: undefined,
  code: undefined,
  consents: undefined,
  dateOfBirth: undefined,
  majorityAge: undefined,
  email: undefined,
  firstName: undefined,
  guardianInfo: undefined,
  isValid: false,
  registerStatus: undefined,
  registerError: undefined,
  registerAs: undefined,
  language: 'en',
  lastName: undefined,
  password: undefined,
  phoneCountry: 'us',
  mobilePhone: undefined,
  receiveSms: false,
  unitOfMeasure: undefined,
  showUnitOfMeasure: false,
  supportedCountries: [],
  fetchCountries: () => ([]),
};

export default withTranslate(AccountInformation);
