import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import _ from 'lodash';
import classnames from 'classnames';
import { daysInMonth, getMonthsForLocale, monthsInYear } from '~/utils/dateUtils';
import withTranslate from '../../shared/withTranslate/withTranslate';
import Select from '../Select/Select';
import './style.sass';

const firstYear = 1898;

const allYears = (() => {
  // fill with all dates in range to current year
  const years = _.range(firstYear, new Date(Date.now()).getFullYear() + 1).reverse();

  // add placeholder, which has value "0"
  years.unshift(0);

  return years;
})();

const allMonths = _.range(0, 13);

const allDays = _.range(0, 32);

class DobPicker extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutID = undefined;
    this.state = {
      year: this.props.initialDate ? this.props.initialDate.year() : 0,
      month: this.props.initialDate ? this.props.initialDate.month() + 1 : 0,
      day: this.props.initialDate ? this.props.initialDate.date() : 0,
      touched: false,
      isManagingFocus: false,
    };
    this.changeYear = this.changeYear.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
    this.changeDay = this.changeDay.bind(this);
    this.blur = this.blur.bind(this);
    this.focus = this.focus.bind(this);
  }

  getMonthsDiplay() {
    const monthsDisplay = getMonthsForLocale(this.props.language);
    // add placeholder, which has value "0"
    monthsDisplay.unshift(this.props.t('month'));
    return monthsDisplay;
  }

  getValidMonths() {
    return allMonths.slice(0, monthsInYear(this.state.year) + 1);
  }

  getValidDays() {
    return allDays.slice(0, daysInMonth(this.state.month, this.state.year) + 1);
  }

  touch() {
    this.setState({ touched: true });
  }

  isTouched() {
    return this.state.touched;
  }

  changeYear(year) {
    const newDate = { year, month: this.state.month, day: this.state.day };

    // month may be invalid if current year
    if (newDate.month > monthsInYear(newDate.year)) {
      newDate.month = 0;
    }

    // day may be invalid based on month
    if (newDate.day > daysInMonth(newDate.month, newDate.year)) {
      newDate.day = 0;
    }

    this.setState(newDate);
    this.notifyDateChange(newDate);
    this.touch();
  }

  changeMonth(month) {
    const newDate = { year: this.state.year, month, day: this.state.day };

    if (newDate.day > daysInMonth(month, newDate.year)) {
      newDate.day = 0;
    }

    this.setState(newDate);
    this.notifyDateChange(newDate);
    this.touch();
  }

  changeDay(day) {
    const newDate = { year: this.state.year, month: this.state.month, day };
    this.setState(newDate);
    this.notifyDateChange(newDate);
    this.touch();
  }

  notifyDateChange(newDate) {
    const { year, month, day } = newDate;
    let date;
    if (!this.props.onDateChange) {
      return;
    }

    if (year && month && day) {
      date = moment.utc().year(year).month(month - 1).date(day)
        .hour(0).minute(0).second(0).millisecond(0); // eslint-disable-line
    }

    this.props.onDateChange(date);
  }

  isValid() {
    const { year, month, day } = this.state;
    return year && month && day;
  }

  classNames(selectName) {
    if (selectName === 'label') {
      return classnames({
        'DobPicker-label': true,
        error: this.state.touched && (!this.state.day || !this.state.month || !this.state.year),
      });
    }
    return classnames({
      'DobPicker-part': true,
      error: this.state.touched && !this.state[selectName],
      [selectName]: true,
    });
  }

  blur() {
    this.timeoutID = setTimeout(() => {
      if (this.state.isManagingFocus) {
        this.setState({ isManagingFocus: false });
      }
    }, 0);
  }

  focus() {
    clearTimeout(this.timeoutID);
    if (!this.state.isManagingFocus) {
      this.setState({
        isManagingFocus: true,
      });
    }
  }

  renderDay() {
    const days = this.getValidDays().map(day => (
      { value: day, displayValue: !day ? this.props.t('day') : day }
    ));

    return (
      <div className={this.classNames('day')} key="day">
        <Select
          name="day"
          value={this.state.day}
          options={days}
          onChange={(value) => { this.changeDay(parseInt(value, 10)); }}
        />
      </div>
    );
  }

  renderMonth() {
    const monthsDisplay = this.getMonthsDiplay();
    const months = this.getValidMonths().map(month => (
      { value: month, displayValue: monthsDisplay[month] }
    ));

    return (
      <div className={this.classNames('month')} key="month">
        <Select
          name="month"
          value={this.state.month}
          options={months}
          onChange={(value) => { this.changeMonth(parseInt(value, 10)); }}
        />
      </div>
    );
  }

  renderYear() {
    const years = allYears.map(year => (
      { value: year, displayValue: !year ? this.props.t('year') : year }
    ));
    return (
      <div className={this.classNames('year')} key="year">
        <Select
          name="year"
          value={this.state.year}
          options={years}
          onChange={(value) => { this.changeYear(parseInt(value, 10)); }}
        />
      </div>
    );
  }

  renderDateField(part) {
    switch (part) {
      case 'day':
        return this.renderDay();
      case 'month':
        return this.renderMonth();
      case 'year':
        return this.renderYear();
      default:
        return null;
    }
  }

  render() {
    const dateFormatOrder =
      new Intl.DateTimeFormat(this.props.language).formatToParts(new Date('2021-01-01'))
        .filter(part => ['day', 'month', 'year'].includes(part.type))
        .map(part => part.type);

    return (
      <div className="DobPicker" onBlur={this.blur} onFocus={this.focus}>
        {this.props.label &&
          <div className={this.classNames('label')}>
            { this.props.label }
          </div>
        }
        <div className="DobPicker-field">
          {dateFormatOrder.map(part => this.renderDateField(part))}
        </div>
      </div>
    );
  }
}

DobPicker.propTypes = {
  initialDate: momentPropTypes.momentObj,
  label: PropTypes.string,
  language: PropTypes.string,
  onDateChange: PropTypes.func,
  t: PropTypes.func.isRequired,
};

DobPicker.defaultProps = {
  initialDate: null,
  label: undefined,
  language: 'en',
  onDateChange: () => {},
};

export default withTranslate(DobPicker);

