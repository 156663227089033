import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import images from '~/utils/ImageStore';
import TextField from '../TextField/TextField';
import withTranslate from '../../shared/withTranslate/withTranslate';
import './style.sass';

export class PasswordField extends Component {
  constructor() {
    super();
    this.valueChange = this.valueChange.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.toggleHide = this.toggleHide.bind(this);
    this.state = {
      hidden: true,
      focused: false,
      correctSize: false,
      strength: 0,
    };
  }

  valueChange(value) {
    this.props.onChange(value);

    if (this.props.withStrengthMeter) {
      this.state.correctSize = this.props.sizeValid(value);
      this.state.strength = this.props.strengthValue(value);
    }
  }

  toggleHide() {
    this.setState({ hidden: !this.state.hidden });
    this.textField.focus();
  }

  focus() {
    this.setState({ focused: true });
  }

  blur() {
    this.setState({ focused: false });
  }

  imageUrl() {
    if (this.state.hidden && this.state.focused) {
      return images.showPasswordSelected;
    } else if (this.state.hidden && !this.state.focused) {
      return images.showPasswordNotSelected;
    } else if (!this.state.hidden && this.state.focused) {
      return images.hidePasswordSelected;
    } else if (!this.state.hidden && !this.state.focused) {
      return images.hidePasswordNotSelected;
    }
    return images.showPasswordNotSelected;
  }

  scoreString = (score) => {
    switch (score) {
      case 0:
      case 1:
        return this.props.t('weak');
      case 2:
        return this.props.t('fair');
      case 3:
        return this.props.t('good');
      case 4:
        return this.props.t('excellent');
      default:
        return '';
    }
  }

  classes() {
    return classnames({
      'PasswordField-field': true,
      focused: this.state.focused,
      error: this.showError(),
    });
  }

  showError() {
    return (
      this.props.value.length > 0 &&
      !this.state.focused &&
      (!this.state.correctSize || this.state.strength < 2)
    );
  }

  showStrengthMeter() {
    if (!this.props.withStrengthMeter) return false;
    if (this.state.focused) return true;

    return this.props.value.length > 0;
  }

  renderField() {
    return (
      <div className={this.classes()}>
        <TextField
          caption={this.props.withStrengthMeter ? '' : this.props.t('passwordRequirements')}
          isPassword={this.state.hidden}
          label={this.props.t('password')}
          onBlur={this.blur}
          onChange={this.valueChange}
          onKeyPress={this.props.onKeyPress}
          onFocus={this.focus}
          value={this.props.value}
          ref={(el) => { this.textField = el; }}
        >
          <button onClick={this.toggleHide} type="button">
            <img alt="show/hide password" src={this.imageUrl()} />
          </button>
        </TextField>
        { this.showStrengthMeter() && (
        <div className="strength-block" >
          <div className="strengthbar">
            <span className={`strength state${this.props.value && this.state.strength}`} />
          </div>
          <div className="correct">{this.props.t('passwordStrength')}
            <span className="strength-value">{this.props.value && this.scoreString(this.state.strength)}</span>
          </div>
          <div className={this.state.correctSize ? 'correct' : 'error'}>
            {this.state.correctSize ? <img src={images.check} alt="&#x2713;" /> : <img src={images.redClose} alt="&#x2715;" />}
            {this.props.t('minimumCharacters')}
          </div>
          <div className={this.state.strength > 1 ? 'correct' : 'error'}>
            {this.state.strength > 1 ? <img src={images.check} alt="&#x2713;" /> : <img src={images.redClose} alt="&#x2715;" />}
            {this.props.t('atLeastStrength')}
          </div>
        </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="PasswordField">
        { this.renderField() }
      </div>
    );
  }
}

PasswordField.propTypes = {
  withStrengthMeter: PropTypes.bool,
  sizeValid: PropTypes.func,
  strengthValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  value: PropTypes.string,
  t: PropTypes.func.isRequired,
};

/* istanbul ignore next */
PasswordField.defaultProps = {
  withStrengthMeter: false,
  sizeValid: () => true,
  strengthValue: () => 0,
  value: '',
};

export default withTranslate(PasswordField);
