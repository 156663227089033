import React, { Component } from 'react';
import qs from 'query-string';
import images from '../../utils/ImageStore';
import { getCountry, getMyGlookoUrl, getRedirectUrl, getCid, getLocale, getCode } from '../../utils/navigation';
import './style.sass';

class AutoInsuletWebLink extends Component {
  componentDidMount() {
    const redirectUrl = getRedirectUrl() || '';
    const code = getCode() || '';
    const country = getCountry() || 'usa';
    const cid = getCid() || '';
    const locale = getLocale() || '';
    window.scrollTo({ top: 0 });
    window.location.assign(`${getMyGlookoUrl()}/op5-confirm-clinic/?${qs.stringify({
      redirectUrl,
      code,
      country,
      cid,
      locale,
    })}`);
  }

  render() {
    return (
      <div className="AutoInsuletWebLink">
        <img className="AutoInsuletWebLink-image" src={images.preloader} alt="spinner" />
      </div>
    );
  }
}

export default AutoInsuletWebLink;
