import React from 'react';
import PropTypes from 'prop-types';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '~/utils/links';
import './style.sass';

const EmailActivationFooter = ({ t }) => (
  <div className="EmailActivationFooter">
    <div className="EmailActivationFooter-content">
      <div>
        <div className="EmailActivationFooter-content-left">
          <div className="EmailActivationFooter-content-text">
            {t('visitGlooko')}
          </div>
          <div>
            <a href="https://support.glooko.com" target="_blank" rel="noopener noreferrer">support.glooko.com</a>
          </div>
        </div>
        <div className="EmailActivationFooter-content-right">
          <div>
            © {new Date(Date.now()).getFullYear()} {t('copyright')}
          </div>
          <div>
            MKT-0087 01
          </div>
          <div className="Footer-privacyPolicy">
            <a
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('privacyPolicy')}
            </a> &nbsp;|&nbsp;
            <a
              href={TERMS_OF_USE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('termsOfUse')}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

EmailActivationFooter.propTypes = {
  t: PropTypes.func.isRequired,
};

export default EmailActivationFooter;
