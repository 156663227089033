import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from '@glooko/common-ui';
import { getLogInURL } from '~/utils/links';
import withTranslate from '../../shared/withTranslate/withTranslate';
import './style.sass';

class MinorParentalConsentActivation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActivatedSuccess: false,
    };
    this.onClickAgreeDisagreeButton = this.onClickAgreeDisagreeButton.bind(this);
  }

  UNSAFE_componentWillMount() { // eslint-disable-line camelcase
    const { userDecryptedDetails, language } = this.props;
    const { email, partnerConsent } = userDecryptedDetails;
    this.props.fetchParentalConsents(email, language, partnerConsent);
  }

  UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line camelcase
    const { registerStatus } = this.props;
    if (registerStatus !== nextProps.registerStatus) {
      this.updateMinorDetails(nextProps);
    }
  }

 onClickAgreeDisagreeButton = (c, version) => {
   const {
     onMinorParentalConsent, userDecryptedDetails,
   } = this.props;
   const { email, partnerConsent } = userDecryptedDetails;
   const choice = c ? 'agree' : 'disagree';
   const payload = {
     consents: partnerConsent,
     email,
     choice,
     version,
   };
   onMinorParentalConsent(payload);
 }

 onClickLogin = () => {
   const { language } = this.props;
   const loginUrl = getLogInURL(language);
   window.location.assign(loginUrl);
 }

 updateMinorDetails = (nextProps) => {
   const { registerStatus } = nextProps;
   if (registerStatus === 'registered') {
     this.setState({ isActivatedSuccess: true });
   }
 }

 render() {
   const {
     isActivatedSuccess,
   } = this.state;
   const {
     t, userDecryptedDetails, consents, isConsentLoading,
   } = this.props;
   const { partnerConsent } = userDecryptedDetails;
   let partnerVersion = null;
   const isInsuletPartner = pc => pc && pc.toLowerCase() === 'insulet';

   if (!isInsuletPartner(partnerConsent) && isConsentLoading) {
     return (
       <div className="MinorEmailActivation">
         <div className="MinorEmailActivation-loading">
           <Spinner className="MinorEmailActivation-spinner" size="large" />
         </div>
       </div>
     );
   }

   let cdn = null;
   let cdnPartner = null;
   if (consents.length > 0) {
     const { cdnLocation, version, partner } = consents[0];
     cdn = cdnLocation;
     partnerVersion = version;
     cdnPartner = partner;
   }


   return (
     <div className="MinorEmailActivation">
       <div className="MinorEmailActivation-content">
         {
          !isActivatedSuccess &&
          <div className="MinorEmailActivation-title">
            <div className="MinorEmailActivation-float_left">
              {t('dialoqConsent')}
              <div className="MinorEmailActivation-partnerdiv"> { partnerConsent } </div>
            </div>
          </div>
         }
         {
          !isActivatedSuccess &&
          <div>
            <div className="MinorEmailActivation-body">
              { isInsuletPartner(partnerConsent) &&
              <div className="MinorEmailActivation-description">
                <br />
                <p className="MinorEmailActivation-paragraph">
                  {t('InsuletContent')}
                  <a
                    href="https://www.omnipod.com/privacy"
                    className="MinorEmailActivation-privacyPolicyLink"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t('insuletPrivacyPolicy')}
                  </a>
                  &nbsp;
                  {t('insuletContentRemainingDesc')}
                </p>
                <p>
                  {t('consentStatement2')}
                </p>
                <p>
                  {t('consentStatement3')}
                  <a
                    href="https://www.glooko.com/privacy"
                    className="MinorEmailActivation-privacyPolicyLink"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t('PrivacyPolicy')}
                  </a>
                </p>
                <p>
                  {t('consentStatement4')}
                </p>
              </div>}
              {!isConsentLoading && !isInsuletPartner(cdnPartner) &&
              <div className="MinorEmailActivation-body">
                <iframe
                  title="iframe-consentloading"
                  className="MinorEmailActivation-iframe"
                  src={cdn}
                />
              </div>
              }
            </div>
            <div className="MinorEmailActivation-button_div">
              <p className="MinorEmailActivation-button_paragraph">
                <Button
                  className="MinorEmailActivation-secondary_button"
                  onClick={() => this.onClickAgreeDisagreeButton(false, partnerVersion)}
                  dataAttributes={{ testid: 'minor-dialog-activation-agree' }}
                  variation="secondary"
                >
                  {t('disagree')}
                </Button>
              </p>
              <p className="MinorEmailActivation-button_paragraph">
                <Button
                  className="MinorEmailActivation-primary_button"
                  onClick={() => this.onClickAgreeDisagreeButton(true, partnerVersion)}
                  dataAttributes={{ testid: 'minor-dialog-activation-agree' }}
                >
                  {t('agree')}
                </Button>
              </p>
            </div>
          </div>
         }
         {
          isActivatedSuccess &&
          <div>
            <div className="MinorEmailActivation-title">
              <div className="MinorEmailActivation-confirmation_title"> {t('confirmation')} </div>
            </div>
            <br />
            <p className="MinorEmailActivation-confirmation_paragraph"> {t('thankYou')} </p>
            <p className="MinorEmailActivation-confirmation_paragraph"> {t('registeredMessage')} </p>
            <div className="MinorEmailActivation-padding MinorEmailActivation-button_div">
              <Button
                onClick={this.onClickLogin}
                className="MinorEmailActivation-primary_button"
                dataAttributes={{ testid: 'minor-dialog-activation-login' }}
              >
                {t('login')}
              </Button>
            </div>
          </div>
         }
       </div>
     </div>
   );
 }
}

MinorParentalConsentActivation.propTypes = {
  t: PropTypes.func.isRequired,
  userDecryptedDetails: PropTypes.object.isRequired,
  fetchParentalConsents: PropTypes.func,
  onMinorParentalConsent: PropTypes.func.isRequired,
  consents: PropTypes.array,
  isConsentLoading: PropTypes.bool,
  language: PropTypes.string,
  registerStatus: PropTypes.string,
};

MinorParentalConsentActivation.defaultProps = {
  language: 'en',
  registerStatus: '',
  consents: [],
  isConsentLoading: true,
  fetchParentalConsents: () => ([]),
};


export default withTranslate(MinorParentalConsentActivation);
