/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, DobPicker } from '@glooko/common-ui';
import classNames from 'classnames';
import moment from 'moment';
import withTranslate from '../../shared/withTranslate/withTranslate';
import './style.sass';

export const QRCodeActivation = (props) => {
  const [getStartedClicked, setGetStartedClicked] = useState(false);
  const [dobNotMatched, setDobNotMatched] = useState(false);

  const {
    onChangeLanguage,
    onQRCodeStartedActivation,
    onQRCodeClickGetStarted,
    onUpdateAttribute,
    onQRCodeSubmitDob,
    userDecryptedDetails,
    dateOfBirth,
    t,
    language,
  } = props;

  useEffect(() => {
    onChangeLanguage(userDecryptedDetails.locale);
    onQRCodeStartedActivation();
  }, []);

  const handleClickOnGetStarted = () => {
    setGetStartedClicked(true);
    onQRCodeClickGetStarted();
  };

  const handleOnDobInput = (date) => {
    onUpdateAttribute({ attribute: 'dateOfBirth', value: date });
  };

  const handleOnSubmit = () => {
    const dobReceived = userDecryptedDetails.dob.split(' ')[0];

    if (dobReceived !== dateOfBirth) {
      setDobNotMatched(true);
      return;
    }
    onQRCodeSubmitDob();
    window.open(userDecryptedDetails.branch_link, '_self');
  };

  const handleOnButtonClick = () => {
    if (getStartedClicked) return handleOnSubmit();
    return handleClickOnGetStarted();
  };

  const {
    first_name,
    last_name,
    clinic_name,
  } = userDecryptedDetails;
  const dobPlaceholders = {
    day: t('day'),
    month: t('month'),
    year: t('year'),
  };
  const dobMoment = moment(dateOfBirth);
  const disableSubmit = !moment.isMoment(dobMoment);
  return (
    <div className="qrCodeActivationScreen">
      {!getStartedClicked ? (
        <div>
          <div className="qrCodeActivationScreen-welcome">
            {t('welcome', { name: first_name })}
          </div>
          <div className="qrCodeActivationScreen-clinicInviteMessageBlock">
            <span className="qrCodeActivationScreen-clinicName">
              {clinic_name}
            </span>
            &nbsp;
            <span>{t('invitingMessage')}</span>
          </div>
          <div className="qrCodeActivationScreen-detailsBox">
            <div>
              <div className="qrCodeActivationScreen-detailsBox-name">
                {t('name')}
              </div>
              <div className="qrCodeActivationScreen-detailsBox-fullName">{`${first_name} ${last_name}`}</div>
              <div className="qrCodeActivationScreen-detailsBox-careTeam">
                {t('careTeam')}
              </div>
              <div className="qrCodeActivationScreen-detailsBox-clinicName">
                {clinic_name}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="qrCodeActivationScreen-getStartedContainer">
          <div className="qrCodeActivationScreen-welcome-confirmation">
            {t('welcome', { name: first_name })}
          </div>
          <div className="qrCodeActivationScreen-pleaseConfirmDob">
            {t('pleaseConfirmDob')}
          </div>
          <div className="qrCodeActivationScreen-dobContainerWrapper">
            <div
              className={classNames({
                'qrCodeActivationScreen-dobContainer': true,
                'qrCodeActivationScreen-error': dobNotMatched,
              })}
            >
              <DobPicker
                locale={language}
                placeholders={dobPlaceholders}
                onChange={handleOnDobInput}
              />
            </div>
            {dobNotMatched && (
              <div className="qrCodeActivationScreen-inputCorrectDob">
                {t('inputCorrectDob')}
              </div>
            )}
          </div>
        </div>
      )}
      <Button
        className="qrCodeActivationScreen-button"
        onClick={handleOnButtonClick}
        disabled={getStartedClicked && disableSubmit}
        dataAttributes={{ testid: 'qr-code-activation-confirmation' }}
      >
        {!getStartedClicked ? t('getStarted') : t('submit')}
      </Button>
    </div>
  );
};

QRCodeActivation.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string,
  dateOfBirth: PropTypes.string,
  onChangeLanguage: PropTypes.func.isRequired,
  onUpdateAttribute: PropTypes.func.isRequired,
  userDecryptedDetails: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    clinic_name: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    branch_link: PropTypes.string.isRequired,
  }).isRequired,
  onQRCodeStartedActivation: PropTypes.func.isRequired,
  onQRCodeClickGetStarted: PropTypes.func.isRequired,
  onQRCodeSubmitDob: PropTypes.func.isRequired,
};
QRCodeActivation.defaultProps = {
  language: 'en',
  dateOfBirth: undefined,
};

export default withTranslate(QRCodeActivation);
